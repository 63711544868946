<template>
  <div>
    <b-card>
      <AgGrid
        ref="OneStepupGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      />
    </b-card>

    <b-modal
      id="UpdateContainerModal"
      ref="UpdateContainer"
      title="Update Container"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="UpdateContainerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="ETD"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>ETD:</b></i></label>
                  <b-form-datepicker
                    v-model="container.SailDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="ETA"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>ETA:</b></i></label>
                  <b-form-datepicker
                    v-model="container.ETA"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="ATD"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>ATD:</b></i></label>
                  <b-form-datepicker
                    v-model="container.ATD"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="ATA"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>ATA:</b></i></label>
                  <b-form-datepicker
                    v-model="container.ATA"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UpdateContainer()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Miscellaneous/OneStepUpContainerBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      TempLineId: '',
      aggrid: [],
      ContainerTypeOption: [],
      container: {
        Id: '',
        Container: '',
        SailDate: '',
        ETA: '',
        Vessel: '',
        MBL: '',
        LoadPort: '',
        DischargePort: '',
        ATD: '',
        ATA: '',
      },
      Role: '',
      Permission: [],

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = 'c3FsMDEuc21hcnRlY2hjLmNvbSwxNDg4O0FwZXhPbmVTdGVwVXBTeXN0ZW07YXBleGFiaTtBcGV4QUJJIQ=='
    // Get user role
    this.Role = this.GetUserRole()

    this.aggrid = this.InitGridInstance('OneStepUpContainers')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'Container', field: 'container', minWidth: 100 },
      {
        headerName: 'Apex',
        field: 'isApexContainer',
        minWidth: 80,
        filter: 'agSetColumnFilter',
      },
      { headerName: 'MBL', field: 'mbl', minWidth: 100 },

      {
        headerName: 'ETD',
        field: 'sailDate',
        minWidth: 125,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'ETA',
        field: 'eta',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'ATD',
        field: 'atd',
        minWidth: 125,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'ATA',
        field: 'ata',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      { headerName: 'Vessel', field: 'vessel', minWidth: 100 },
      { headerName: 'Load Port', field: 'loadPort', minWidth: 100 },
      { headerName: 'Discharge Port', field: 'dischargePort', minWidth: 100 },
      {
        headerName: 'Created On',
        field: 'createdOn',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      { headerName: 'Last Updated', field: 'updatedBy', minWidth: 100 },
      {
        headerName: 'Updated On',
        field: 'updatedOn',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.OneStepupGrid.saveState('OneStepUpContainers') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.OneStepupGrid.saveState('OneStepUpContainers') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.OneStepupGrid.saveState('OneStepUpContainers') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.OneStepupGrid.saveState('OneStepUpContainers') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(ContainerId) {
      this.ResetContainer()
      this.$refs.UpdateContainer.show()
      this.LoadContainer(ContainerId)
    },
    DoubleClickOpenPopup(params) {
      this.ResetContainer()
      this.$refs.UpdateContainer.show()
      this.LoadContainer(params.data.id)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadContainers()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadContainers() {
      // Get permission
      this.Permission = this.GetUserPermission('Misc')
      return axios.get('/misc/onestepup/containers/active')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.OneStepupGrid.ResetColumns()
        })
    },
    LoadContainer(ContainerId) {
      return axios.get(`/misc/onestepup/containers/${ContainerId}`).then(resp => {
        this.LoadingStart()
        const response = resp.data
        const respdata = response
        this.container.Id = respdata.id
        this.container.Container = respdata.container
        this.container.SailDate = new Date(respdata.sailDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.sailDate) : null
        this.container.ETA = new Date(respdata.eta).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.eta) : null
        this.container.Vessel = respdata.vessel
        this.container.MBL = respdata.mbl
        this.container.LoadPort = respdata.loadPort
        this.container.DischargePort = respdata.dischargePort
        this.container.ATD = new Date(respdata.atd).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.atd) : null
        this.container.ATA = new Date(respdata.ata).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.ata) : null
      }).then(() => {
        this.LoadingEnd()
      })
    },
    UpdateContainer() {
      this.$refs.UpdateContainerRules.validate().then(success => {
        if (success) {
          const ContainerListModel = {
            SailDate: new Date(this.container.SailDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.container.SailDate).toJSON().split('T')[0] : null,
            ETA: new Date(this.container.ETA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.container.ETA).toJSON().split('T')[0] : null,
            ATD: new Date(this.container.ATD).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.container.ATD).toJSON().split('T')[0] : null,
            ATA: new Date(this.container.ATA).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.container.ATA).toJSON().split('T')[0] : null,
          }
          // Post
          axios.put(`/misc/onestepup/containers/${this.container.Id}`, ContainerListModel)
            .then(() => {
              this.DisplaySuccess('Details have been saved.').then(() => {
                this.$refs.UpdateContainer.hide()
                this.LoadContainers().then(() => {
                  this.LoadingEnd()
                })
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.DisplayError('Details cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Container cannot be updated.', 'Please check data requirements.')
        }
      })
    },
    Delete(containerId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/misc/onestepup/containers/${containerId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Container has been removed.')
              .then(() => {
                // redirect
                this.LoadContainers()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Container cannot be removed.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ResetContainer() {
      this.container = {
        Id: '',
        Container: '',
        SailDate: '',
        ETA: '',
        Vessel: '',
        MBL: '',
        LoadPort: '',
        DischargePort: '',
        ATD: '',
        ATA: '',
      }
    },
  },
}
</script>
